import React from "react"
import Layout from "../../components/layout"
import AboutSubnav from "../../components/about_subnav"
import ColophonCopy from "../../copy/colophon"

export default function Colophon({data}) {
  return (
    <Layout className="about_page" pageTitle="Colophon">
      <AboutSubnav></AboutSubnav>

      <article id="colophon" className="hasSidenav">
        <ColophonCopy />			
		  </article>

      <div className="clearfix"></div>
    </Layout>
  )
}